@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url('./images/bg_paperbag_devaintart_257370767.jpeg');
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  margin: 0;
}

.top-section-header {
  /*display: block;*/
  margin: 0;
}

#style-switcher {
  /*display: block;*/
  width: 100%;
  /*float: left;*/
}

form.style-switcher select{
  background-color: transparent;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button { margin-top: 7px; }

@layer base {

  p {
    @apply text-base;
    color: #493f36;
  }

  h1 {
    @apply text-4xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  h4 {
    @apply text-xl;
  }

  h5 {
    @apply text-lg;
    @apply font-bold;
  }

  ul {
    margin-left: 1.5em;
    margin-top: 1.5em;
  }

  li {
    color: #493f36;
    @apply text-base;
    margin-bottom: .95em;
  }
}
